function nextFrame ( el, frameArray,  whichFrame, nextF) {		
    if( whichFrame >= frameArray.length ) {        
        if(nextF !== undefined){         
            nextF();
        }
        return;
    }
    el.animate( frameArray[ whichFrame ].animation,
                frameArray[ whichFrame ].dur,
                mina.easeinout, 
                nextFrame.bind( null, el, frameArray, whichFrame + 1, nextF ) );
}

function isScrolledIntoView(elem)
{
    var $elem = jQuery(elem);
    var $window = jQuery(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();
    if($elem.offset() === undefined) {return false;}

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    return ((docViewTop < elemTop) && (docViewBottom > elemBottom));
}


var grow        = [
                        {animation: {width: 500}, dur: 500},
                        {animation: {height: 200}, dur: 500}
                ];
var fadeOutIn   = [
                        {animation: {opacity: 0}, dur: 1000},
                        {animation: {opacity: 1}, dur: 1000}
                ];
var fadeOutIn   = [
                        {animation: {opacity: 0}, dur: 1000},
                        {animation: {opacity: 1}, dur: 1000}
                ]; 
var fadeIn      = [                        
                        {animation: {opacity: 1}, dur: 1000}
];
var fadeOut      = [                        
                        {animation: {opacity: 0}, dur: 1000}
];

var fireSwitch = [
    {animation: {fill:"yellow"}, dur: 500},
    {animation: {fill:"orange"}, dur: 500},
    {animation: {fill:"yellow"}, dur: 500},
    {animation: {fill:"orange"}, dur: 500},
    {animation: {fill:"white"}, dur: 500}
];



var scrollAllow = true;
var animated = false;
jQuery(window).scroll(function(){

    if(!scrollAllow || animated) {return;}

    scrollAllow = false;
    if(isScrolledIntoView('#svgObjects')){
        animated = true;
        setTimeout(function(){
            var f = new Snap('#full-building-control');

            var lineOne     = f.select('#lineOne');
            var lineTwo     = f.select('#lineTwo');
            var lineThree   = f.select('#lineThree');
            var lineFour    = f.select('#lineFour');
            var tick        = f.select('#tick');

            nextFrame(lineOne,fadeOutIn,0);
            nextFrame(lineTwo,fadeOutIn,0);
            nextFrame(lineThree,fadeOutIn,0);
            nextFrame(lineFour,fadeOutIn,0,function(){
                nextFrame(tick,fadeIn,0);
            });



        },1000);

        setTimeout(function(){
            var bf = new Snap('#beautiful-finishes');


            var left     = bf.select('#left');
            var right     = bf.select('#right');

            var firstScene = new Snap.Matrix();
            bbox = left.getBBox();
            firstScene.scale(1,1, bbox.cx, bbox.cy);
            firstScene.rotate(45,bbox.cx, bbox.cy);
            left.animate({ transform: firstScene},2000, mina.bounce);

            var secondScene = new Snap.Matrix();
            bbox = right.getBBox();
            secondScene.scale(1,1, bbox.cx, bbox.cy);
            secondScene.rotate(-45,bbox.cx, bbox.cy);
            right.animate({ transform: secondScene},2000, mina.bounce);



        },2000);

        setTimeout(function(){
            var ef = new Snap('#energy-efficient');

            var fire     = ef.select('#polyfire');

            nextFrame(fire,fadeOutIn,0, function(){
                nextFrame(fire,fadeOutIn,0);
            });
            nextFrame(fire,fireSwitch,0);

            var scale = new Snap.Matrix();
            bbox = fire.getBBox();
            scale.scale(0.7,0.7, bbox.cx, bbox.cy);
            fire.animate({ transform: scale},2000, mina.bounce, function(){
                bbox = fire.getBBox();
                scale.scale(1.3,1.3, bbox.cx, bbox.cy);
                fire.animate({ transform: scale},2000, mina.bounce);
            });

        },3000);

        setTimeout(function(){
            var fi = new Snap('#fast-installation');

            var arrow   = fi.select('#arrow');
            var cog     = fi.select('#cog');

            nextFrame(arrow,fadeOutIn,0);

            var rotatecog = new Snap.Matrix();
            bbox = cog.getBBox();
            rotatecog.rotate(-45,bbox.cx, bbox.cy);
            cog.animate({ transform: rotatecog},2000, mina.elastic);

        },4000);


    }

    setTimeout(function(){
        scrollAllow = true;
    },100);

});

jQuery('document').ready(function(){

    jQuery('.cross-pins').each(function(){

        var pin = new Snap("#" + jQuery(this).attr('id'));

        var pinCirc     = pin.select('.circ');

        pin.mouseover(function(){
            bbox = pinCirc.getBBox();
            var scale = new Snap.Matrix();
            scale.scale(0.7,0.7, bbox.cx, bbox.cy);
            pinCirc.animate({ transform: scale},1000, mina.bounce);

        });
        pin.mouseout(function(){
            bbox = pinCirc.getBBox();
            var scale = new Snap.Matrix();
            scale.scale(1,1, bbox.cx, bbox.cy);
            pinCirc.animate({ transform: scale},1000, mina.bounce);
        });
    });
});